<template>
  <div class="contact-form">
    <div class="contact-form__characters-content">
      <RCInput
        class="contact-form__information"
        :class="emptyFields['first_name'] === true ? 'missing-field' : ''"
        :label="$t('stopsInfo.createStopInfo.firstName')"
        :floatLabel="true"
        :requiredField="true"
        type="text"
        v-model="contactData.first_name"
        :minlength="inputLimits.first_name.min"
        :maxlength="inputLimits.first_name.max"
        @input="handleMissingStyles($event, 'first_name')"
      />
      <span v-show="contactData.first_name.length > 25" class="contact-form__validation-characters">
        * Must be no longer than 25 characters
      </span>
    </div>
    <div class="contact-form__characters-content">
      <RCInput
        class="contact-form__information"
        :class="emptyFields['last_name'] === true ? 'missing-field missing-field--lastname' : ''"
        :label="$t('stopsInfo.createStopInfo.lastName')"
        :floatLabel="true"
        :requiredField="true"
        type="text"
        v-model="contactData.last_name"
        :minlength="inputLimits.last_name.min"
        :maxlength="inputLimits.last_name.max"
        @input="handleMissingStyles($event, 'last_name')"
      />
      <span v-show="contactData.last_name.length > 25" class="contact-form__validation-characters">
        * Must be no longer than 25 characters
      </span>
    </div>
    <div class="contact-form__phone-number-content">
      <InputPhone
        @changePhone="handlePhoneNumber"
        @changeFlag="handleSelectedFlag"
        :emptyFields="emptyFields"
      ></InputPhone>
    </div>
    <div class="contact-form__email-content">
      <RCInput
        class="contact-form__information contact-form__information--email"
        :class="emptyFields['email'] === true ? 'missing-field' : ''"
        :type="'email'"
        :label="$t('stopsInfo.createStopInfo.email')"
        :floatLabel="true"
        :requiredField="true"
        v-model="contactData.email"
        :minlength="inputLimits.email.min"
        :maxlength="inputLimits.email.max"
        @input="handleMissingStyles($event, 'email')"
      />
      <span v-show="error.email == true" class="contact-form__validation">
        * {{ $t("createAccount.error.validateEmailAdress") }}
      </span>
    </div>
  </div>
</template>

<script>
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import Vue from "vue";
/* import FormatPhoneNumber from "@/Extend/FormatPhoneNumber"; */
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import InputPhone from "../../../../components/InputPhone.vue";

export default {
  /* eslint-disable no-return-assign */
  name: "ContactForm",
  components: {
    RCInput,
    InputPhone,
  },
  /*  mixins: [FormatPhoneNumber], */
  props: {
    contactInformation: Object,
    mode: String,
    contactsList: Array,
    previousModal: String,
  },
  data() {
    return {
      selectedFlag: "",
      remountInput: 0,
      optionsFilters: [
        { label: "USA +1", value: 1 },
        { label: "CAN +1", value: 1 },
        { label: "MEX +52", value: 52 },
      ],
      selectedIndicator: "",
      contactData: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      emptyFields: {
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
      },
      error: {
        email: false,
      },
      contactInfo: null,
      // contactSuggestions: [{ options: [] }],
      showInputs: false,
      inputLimits: {
        first_name: {
          min: 1,
          max: 40,
        },
        last_name: {
          min: 1,
          max: 40,
        },
        phone: {
          min: 7,
          max: 25,
        },
        email: {
          min: 7,
          max: 100,
        },
      },
    };
  },
  watch: {
    contactData: {
      handler() {
        this.$emit("input", this.contactData);
      },
      deep: true,
    },
    "contactData.first_name": {
      handler(newValue) {
        const result = this.replaceNumbersForLetters(newValue);
        Vue.nextTick(() => (this.contactData.first_name = result));
      },
    },
    "contactData.last_name": {
      handler(newValue) {
        const result = this.replaceNumbersForLetters(newValue);
        Vue.nextTick(() => (this.contactData.last_name = result));
      },
    },
  },
  computed: {
    phoneNumberUnformatted() {
      const digitsOnly = this.contactData.phone.replace(/\D/g, "");
      return digitsOnly;
    },
  },
  methods: {
    handlePhoneNumber(newPhoneNumber) {
      this.contactData.phone = newPhoneNumber;
    },
    replaceNumbersForLetters(value) {
      const result = value.toString().replace(/\d/g, "");
      return result;
    },
    validateFormfields() {
      const ruleFirstName = this.validateCharactersLength(this.contactData.first_name);
      const ruleLastName = this.validateCharactersLength(this.contactData.last_name);
      const regexpEmail = this.validateEmails(this.contactData.email);
      if (regexpEmail) {
        this.error.email = false;
      } else {
        this.error.email = true;
      }
      const validatedForm = ValidateEmptyFields(this.contactData);
      this.resetEmptyFieldsValues();
      validatedForm.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      if (validatedForm.validation) {
        this.addPrefixPhoneNumber();
        this.$emit("input", this.contactData);
      }
      if (validatedForm.validation && regexpEmail && ruleFirstName && ruleLastName) {
        return validatedForm.validation;
      }
      return false;
    },
    addPrefixPhoneNumber() {
      let newCountryCode = "";
      switch (this.selectedFlag) {
        case "MEX":
          newCountryCode = "52";
          break;
        case "USA":
        case "CAN":
          newCountryCode = "1";
          break;
        default:
          newCountryCode = "1";
      }

      // Regular expression to find the prefix of the above country code.
      const regex = new RegExp(`^${this.countryCode}`);

      // Remove the previous country code from the phone number
      const currentPhone = this.contactData.phone.replace(regex, "");

      // Add new country code
      this.contactData.phone = newCountryCode + currentPhone;

      // Update the current country code
      this.countryCode = newCountryCode;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
    createNewContact() {
      this.$refs["contact-searcher"].hideDropdown();
      this.showInputs = true;
    },
    validateCharactersLength(character) {
      if (character.length < 26) {
        return true;
      }
      return false;
    },
    handleSelectedFlag(newFlag) {
      if (newFlag !== "") {
        this.selectedFlag = newFlag;
      }
    },
    validatePhoneNumber(number) {
      let response = "";
      const firstCharacter = this.contactData.phone.charAt(0);
      // eslint-disable-next-line eqeqeq
      if (firstCharacter == 1) {
        if (this.contactData.phone) {
          if (number.length === 11) {
            response = true;
          } else {
            response = false;
          }
        }
      } else if (this.contactData.phone) {
        if (number.length === 10) {
          response = true;
        } else {
          response = false;
        }
        return response;
      }
      return response;
    },
    phoneIsNumberOne(value) {
      const phoneString = value;
      return phoneString[0] === "1";
    },
    validateEmails(email) {
      // eslint-disable-next-line max-len
      const regexp = new RegExp(
        /^.*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (regexp.test(email)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/RCComponents.scss";

.contact-form {
  width: 100%;
  &__information {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    margin: 30px auto;
    margin-bottom: 25px;
    &--phone-number {
      margin: 0;
      position: relative;
    }
    &--email {
      margin-bottom: 0;
    }
  }
  &__characters-content {
    position: relative;
  }
  &__phone-number-content {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    border: 1px solid $color-border-container !important;
  }
  &__email-content {
    display: flex;
    flex-direction: column;
    // margin-bottom: 30px;
  }
  &__validation {
    color: red;
    font-size: 0.8rem;
    text-align: right;
  }
  &__validation-phone,
  &__validation-characters {
    color: red;
    font-size: 0.8rem;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  &__validation-phone-moved-bottom {
    bottom: -20px;
  }
}
</style>
