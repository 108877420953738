export default {
  methods: {
    phoneNumberWithoutFormat(phone) {
      const digitsOnly = phone.replace(/\D/g, "");
      return digitsOnly;
    },
    FormatPhoneNumberWithoutPrefix(phone) {
      if (!phone) {
        return null;
      }
      const onlyNumbers = phone.replace(/\D/g, "");
      const regex = /(\d{0,3})(\d{0,3})(\d{0,4})/;
      const match = regex.exec(onlyNumbers);
      if (match) {
        const first = match[1];
        const second = match[2];
        const third = match[3];
        if (first && second && third) {
          return `(${first}) ${second}-${third}`;
        }
        if (first && second) {
          return `(${first}) ${second}`;
        }
        if (first) {
          return `(${first}`;
        }
      }
      return "";
    },
  },
};
