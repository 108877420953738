function validateFields(form) {
  let validation = true;
  const emptyFields = [];
  const formKeys = Object.keys(form);
  for (let i = 0; i < formKeys.length; i += 1) {
    if (Array.isArray(form[formKeys[i]])) {
      if (form[formKeys[i]].length === 0) {
        emptyFields.push(formKeys[i]);
        validation = false;
      }
    } else if (!form[formKeys[i]]) {
      emptyFields.push(formKeys[i]);
      validation = false;
    }
  }
  return { validation, emptyFields };
}

export default validateFields;
