<template>
  <div class="flag-selected-container">
    <el-select
      v-model="selectedFlag"
      placeholder="Indicator"
      :class="selectedFlag === '' ? 'missing-field' : ''"
    >
      <template #prefix>
        <img
          v-if="selectedFlag"
          :src="getSelectedFlag(selectedFlag)"
          class="flag-selector__image-selected"
        />
      </template>
      <el-option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        <div class="flag-selector__option">
          <img :src="option.flag" :alt="option.label" class="flag-selector__image" />
          <span>{{ option.label }}</span>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import usaFlag from "@/assets/images/flags/usa.png";
import canadaFlag from "@/assets/images/flags/canada.png";
import mexicoFlag from "@/assets/images/flags/mexico.png";

export default {
  name: "FlagSelector",
  data() {
    return {
      selectedFlag: "USA",
      options: [
        { label: "+1", value: "USA", flag: usaFlag },
        { label: "+1", value: "CAN", flag: canadaFlag },
        { label: "+52", value: "MEX", flag: mexicoFlag },
      ],
    };
  },
  methods: {
    getSelectedFlag(value) {
      const selectedOption = this.options.find((option) => option.value === value);
      return selectedOption ? selectedOption.flag : "";
    },
  },
  watch: {
    selectedFlag: {
      handler() {
        this.$emit("changeIndicator", this.selectedFlag);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.flag-selector__option {
  display: flex;
  align-items: center;
}

.flag-selector__image {
  width: 40px;
  height: 28px;
  margin-right: 8px;
}

.flag-selector__image-selected {
  width: 24px;
  height: 16px;
  margin-right: 8px;
}
</style>
